/*
import Slider from "rc-slider";
import { FaCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { IconName } from "react-icons/fa6";
import { FaRegSmile, FaRegFrown } from "react-icons/fa";
*/
import React, { useContext, useState, useEffect, useRef } from "react";
import "./Dropdown.css";
import "./LoadingPage.css";
import Select from "react-select";
import Rating from "react-rating";
import axios from "axios";
import { jsPDF } from "jspdf";
import "rc-slider/assets/index.css";
import { useParams, useNavigate } from "react-router-dom";
import DropdownContext from "./DropdownContext";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { LuPencil } from "react-icons/lu";
import { PiWineFill } from "react-icons/pi";
import { LuSend } from "react-icons/lu";
import { FaHourglassEnd } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import ColorSelect from './ColorSelect'; // Adjust the import path as needed
import CreatableSelect from 'react-select/creatable';
import { RiExpandUpDownLine } from "react-icons/ri";
import { TfiClose } from "react-icons/tfi";
import { FaCheck } from "react-icons/fa6";
import LoadingPage from "./LoadingPage";

function DropdownPage() {
  const { dropdownState, setDropdownState } = useContext(DropdownContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formName, setFormName] = useState("");
  const [isExpandedBouche, setIsExpandedBouche] = useState(false);
  const [isExpandedOeil, setIsExpandedOeil] = useState(false);
  const [isExpandedNez, setIsExpandedNez] = useState(false);
  const [isExpandedComment, setIsExpandedComment] = useState(false);
  const [isExpandedNote, setIsExpandedNote] = useState(false);

  const toggleBoucheDropdown = () => {
    setIsExpandedBouche(!isExpandedBouche);
  };

  const toggleOeilDropdown = () => {
    setIsExpandedOeil(!isExpandedOeil);
  };

  const toggleNezDropdown = () => {
    setIsExpandedNez(!isExpandedNez);
  };

  const toggleCommentDropdown = () => {
    setIsExpandedComment(!isExpandedComment);
  };

  const toggleNoteDropdown = () => {
    setIsExpandedNote(!isExpandedNote);
  };

  const handleNameChange = (e) => {
    setFormName(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      // Include the email in the form data
      const formData = {
        ...dropdownState,
        email, // Add the email to the form data
      };
  
      // Send a POST request to the serverless function
      const response = await fetch('/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
  
      const result = await response.json();
      console.log('Data successfully sent to MongoDB:', result);
      navigate("/homepage");
    } catch (error) {
      console.error('Error submitting the data:', error);
    }
  };

  const handleColorChange = (color) => {
    setDropdownState((prevState) => ({ ...prevState, ['selectedColor']: color }));
  };

  // Add free text area for NEZ
  // Elastic search on Nez
  const options = [
    { value: "option1", label: "citron" },
    { value: "option2", label: "pamplemousse" },
    { value: "option3", label: "orange" },
    { value: "option4", label: "ananas" },
    { value: "option5", label: "banane" },
    { value: "option6", label: "litchi" },
    { value: "option7", label: "melon" },
    { value: "option8", label: "muscat" },
    { value: "option9", label: "pomme" },
    { value: "option10", label: "poire" },
    { value: "option11", label: "coing" },
    { value: "option12", label: "fraise" },
    { value: "option13", label: "framboise" },
    { value: "option14", label: "groseille" },
    { value: "option15", label: "cassis" },
    { value: "option16", label: "myrtille" },
    { value: "option17", label: "mûre" },
    { value: "option18", label: "cerise" },
    { value: "option19", label: "abricot" },
    { value: "option20", label: "pêche" },
    { value: "option21", label: "amande (frangipane)" },
    { value: "option22", label: "pruneau" },
    { value: "option23", label: "noix" },
    { value: "option24", label: "aubépine" },
    { value: "option25", label: "acacia" },
    { value: "option26", label: "tilleul" },
    { value: "option27", label: "miel" },
    { value: "option28", label: "rose" },
    { value: "option29", label: "violette" },
    { value: "option30", label: "poivron vert" },
    { value: "option31", label: "champignon" },
    { value: "option32", label: "truffe" },
    { value: "option33", label: "lie de vin" },
    { value: "option34", label: "cèdre" },
    { value: "option35", label: "pin" },
    { value: "option36", label: "réglisse" },
    { value: "option37", label: "bourgeon de cassis (ou buis)" },
    { value: "option38", label: "foin coupé" },
    { value: "option39", label: "thym" },
    { value: "option40", label: "vanille" },
    { value: "option41", label: "cannelle" },
    { value: "option42", label: "clou de girofle" },
    { value: "option43", label: "poivre" },
    { value: "option44", label: "safran" },
    { value: "option45", label: "cuir" },
    { value: "option46", label: "musc" },
    { value: "option47", label: "beurre" },
    { value: "option48", label: "pain grillé" },
    { value: "option49", label: "amande grillée" },
    { value: "option50", label: "noisette grillée" },
    { value: "option51", label: "caramel" },
    { value: "option52", label: "café" },
    { value: "option53", label: "chocolat noir" },
    { value: "option54", label: "note fumée" },
  ];

  const handleInputChange = (field, value) => {
    setDropdownState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleEdit = (field) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [`IsEditing${field}`]: true,
    }));
  };

  const handleSave = (field) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [`Saved${field}`]: prevState[field],
      [`IsEditing${field}`]: false,
    }));
  };

  const handleSelectChange = (selectedOptions, dropdown) => {
    setDropdownState((prevState) => {
      const updatedState = {
        ...prevState,
        [dropdown]: Array.isArray(selectedOptions)
          ? selectedOptions.map((option) => option.value) // Multi-select handling
          : selectedOptions?.value, // Single select handling
      };
      return updatedState;
    });
  };

  const handleSliderChange = (value, ratingName) => {
    setDropdownState((prevState) => ({ ...prevState, [ratingName]: value }));
  };

  const handleSliderSmileyChange = (value, ratingName) => {
    setDropdownState((prevState) => ({ ...prevState, [ratingName]: value }));
  };

  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);

  const generatePDF = async (content) => {
    const pdf = new jsPDF();
    pdf.text(content, 10, 10);
    return pdf.output("blob");
  };

  const updateColorOptions = (input) => {
    if (input.startsWith("RG")) {
      setColorOptions([
        { value: "option1", label: "Violacé", color: "#743754" },
        { value: "option2", label: "Rubis", color: "#cb3607" },
        { value: "option3", label: "Grenat", color: "#b4211e" },
        { value: "option4", label: "Tuilé", color: "#8f4318" },
      ]);
    } else if (input.startsWith("RZ")) {
      setColorOptions([
        { value: "option1", label: "Pâle", color: "#f2b3c8" },
        { value: "option2", label: "Saumon", color: "#ef998c" },
        { value: "option3", label: "Pelure d'oignon", color: "#f2a480" },
        { value: "option4", label: "Framboise", color: "#d04b82" },
      ]);
    } else if (input.startsWith("B")) {
      setColorOptions([
        { value: "option1", label: "Jaune Vert", color: "#e3ff00" },
        { value: "option2", label: "Jaune Citron", color: "#faff00" },
        { value: "option3", label: "Jaune Doré", color: "#ffe500" },
        { value: "option4", label: "Ambré", color: "#e4a400" },
      ]);
    } else {
      setColorOptions([
        { value: "option1", label: "Violacé", color: "#743754" },
        { value: "option2", label: "Rubis", color: "#cb3607" },
        { value: "option3", label: "Grenat", color: "#b4211e" },
        { value: "option4", label: "Tuilé", color: "#8f4318" },
      ]); // Default options
    }
  };

  // Define lists for different types of wine
  const wineLists = {
    RG: ["acidité", "tanins", "volume", "longeur en bouche"],
    RZ: ["acidité", "tanins", "volume", "longeur en bouche"],
    B: ["acidité", "volume", "longueur en bouche"],
  };

  // Determine the list of items based on textarea input
  const getListItems = (input) => {
    try {
      // Ensure input is a string
      if (typeof input !== 'string') {
        throw new Error('Input must be a string');
      }
  
      if (input.startsWith('RG')) return wineLists.RG;
      if (input.startsWith('RZ')) return wineLists.RZ;
      if (input.startsWith('B')) return wineLists.B;
  
      return wineLists.RG; // Default list
    } catch (error) {
      return wineLists.RG; // Return an empty array or default value in case of an error
    }
  };

  const [colorOptions, setColorOptions] = useState([
    { value: "option1", label: "Violacé", color: "#743754" },
    { value: "option2", label: "Rubis", color: "#cb3607" },
    { value: "option3", label: "Grenat", color: "#b4211e" },
    { value: "option4", label: "Tuilé", color: "#8f4318" },
  ]);

  const handleTextareaChangeOeil = (e) => {
    const input = e.target.value;
    setDropdownState((prevState) => ({ ...prevState, ['textAreaContentOeil']: input }));
    updateColorOptions(input);
  };

  const listItems = getListItems(dropdownState.textAreaContentOeil);

  const handleSavePage = () => {
    const wineCode = dropdownState.textAreaContentOeil.toUpperCase();
    const listItems = getListItems(dropdownState.textAreaContentOeil);

    if (!["R", "RZ", "B"].includes(wineCode.charAt(0))) {
      alert("Entrez un nom de code commençant par R, RZ ou B.");
      return;
    }
    
    const pageData = {
      id: id, // Unique identifier for the page
      name: `ma_fiche_${wineCode}`,
      data: {
        ...dropdownState,
        email,
      },
    };

    // Retrieve existing pages from localStorage
    const savedPages = JSON.parse(localStorage.getItem("pages")) || [];
    // Check if page with the same ID already exists
    const updatedPages = savedPages.filter((page) => page.id !== pageData.id);
    updatedPages.push(pageData);
    // Debugging: Check what is being saved
    console.log("Saving page:", { id: id, data: dropdownState });
    localStorage.setItem("pages", JSON.stringify(updatedPages));
    navigate("/homepage"); // Redirect to homepage or another page
  };

  const captureHTMLWithInlineStyles = () => {
    const dropdownContainers = document.querySelectorAll('.dropdown-container');
    dropdownContainers.forEach((container) => {
      container.style.display = 'block';
    });
  
    const htmlContent = `
      <html>
        <head>
          <link rel="stylesheet" href="Dropdown.css">
          <link rel="stylesheet" href="ColorSelect.css">
        </head>
        <body>
          ${document.documentElement.innerHTML}
        </body>
      </html>
    `;
  
    return htmlContent;
  };


  return (
    <div className="dropdown-pop">
      <LoadingPage />
      <div className="btn-close-pop">
        <TfiClose
          className="btn-close-style"
          size={24}
          onClick={handleSavePage}
        />
      </div>
      <div className="white-bloc">
        <div className="input-container">
          <div className="wine-name-bloc">
            {dropdownState.IsEditingWineName ? (
              <div className="bloc-saveWineName">
                <textarea
                  maxLength={20}
                  placeholder="Code du Vin"
                  name="WineName"
                  className="WineName"
                  rows="1"
                  value={dropdownState.textAreaContentOeil}
                  onChange={handleTextareaChangeOeil}
                ></textarea>
                <button onClick={() => handleSave("WineName")}>
                  <FaCheck size={27} />
                </button>
              </div>
            ) : (
              <div>
                <div className="card bloc-saveWineName">
                  <textarea value={dropdownState.textAreaContentOeil} />
                </div>
                <button
                  className="check-button"
                  onClick={() => handleEdit("WineName")}
                >
                  {" "}
                  <LuPencil size={27} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="dropdown-container eye-style">
          {/* Clickable title for toggling expansion */}
          <h3 className="dropdown-list-h3" onClick={toggleOeilDropdown}>
            L'Oeil{" "}
            {isExpandedOeil ? (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            ) : (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            )}{" "}
            {/* Display arrow indicating collapse/expand */}
          </h3>
          {/* Conditionally render the ColorSelect component based on whether the dropdown is expanded */}
          {isExpandedOeil && (
            <ColorSelect
              colors={colorOptions}
              selectedColor={dropdownState.selectedColor}
              onColorChange={handleColorChange}
            />
          )}
        </div>
        <div className="dropdown-container">
          <h3 className="dropdown-list-h3" onClick={toggleNezDropdown}>
            Le Nez{" "}
            {isExpandedNez ? (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            ) : (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            )}{" "}
            {/* Display arrow indicating collapse/expand */}
          </h3>
          {isExpandedNez && (
            <div className="nez-container">
              <CreatableSelect
                isMulti
                name="nez"
                options={options}
                className="basic-multi-select select"
                placeholder="Sélectionner le nez"
                classNamePrefix="custom-select"
                value={dropdownState["selectedOption2"]?.map((value) =>
                  options.find((option) => option.value === value) || { label: value, value }
                )}
                onChange={(selectedOptions) =>
                  handleSelectChange(selectedOptions, 'selectedOption2')
                }
              />
            </div>
          )}
        </div>
        <div className="dropdown-container">
          <h3 className="dropdown-list-h3" onClick={toggleBoucheDropdown}>
            La Bouche{" "}
            {isExpandedBouche ? (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            ) : (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            )}{" "}
            {/* Display an arrow to indicate expand/collapse */}
          </h3>
          {/* Conditionally render the content based on whether the container is expanded */}
          {isExpandedBouche && (
            <>
              {dropdownState.IsEditingTextAreaContent ? (
                <div className="bloc-bouche-flex">
                  <textarea
                    className="txt-commentaire-1"
                    placeholder="Rétro olfaction"
                    value={dropdownState.TextAreaContent}
                    onChange={(e) =>
                      handleInputChange("TextAreaContent", e.target.value)
                    }
                  />
                </div>
              ) : (
                <div className="button-bouche">
                  <p>{dropdownState.SavedTextAreaContent}</p>
                  <button
                    className="edit-pencil"
                    onClick={() => handleEdit("TextAreaContent")}
                  >
                    <LuPencil size={27} />
                  </button>
                </div>
              )}
              <div className="grid-container">
                {listItems.map((ratingName) => (
                  <div key={ratingName} className="rating-container flex-left">
                    <label>
                      {ratingName.charAt(0).toUpperCase() + ratingName.slice(1)}
                      : {dropdownState[ratingName]}
                    </label>
                    <Rating
                      className="rating-color"
                      min={1}
                      max={5}
                      value={dropdownState[ratingName]}
                      onChange={(value) =>
                        handleSliderChange(value, ratingName)
                      }
                      marks={{ 1: "1", 2: "2", 3: "3", 4: "4", 5: "5" }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="dropdown-container">
          <h3 className="dropdown-list-h3" onClick={toggleCommentDropdown}>
            Commentaire{" "}
            {isExpandedComment ? (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            ) : (
              <RiExpandUpDownLine size={24} className="chevron-position" />
            )}{" "}
            {/* Display an arrow to indicate expand/collapse */}
          </h3>
          {isExpandedComment && (
            <textarea
              className="txt-commentaire-2"
              placeholder="Laissez votre commentaire..."
              value={dropdownState.textAreaComment}
              onChange={(e) =>
                handleInputChange("textAreaComment", e.target.value)
              }
            ></textarea>
          )}
        </div>
        <div className="rating-container flex-center">
          <label className="dropdown-list-h3" onClick={toggleNoteDropdown}>
            Ma Notation{" "}
          </label>
          {isExpandedNote && (
            <div className="rating-container-2">
              <Rating
                emptySymbol={<FaStar size={30} color="#dcdcdc" />}
                fullSymbol={<FaStar size={30} color="gold" />}
                initialRating={dropdownState.smileyRating}
                onChange={(value) =>
                  handleSliderSmileyChange(value, "smileyRating")
                }
              />
            </div>
          )}
        </div>
        <div className="flex-send-form">
          <div className="send-form">
            <form>
              <input
                type="text"
                name="formName"
                value={dropdownState.textAreaContentOeil}
                onChange={handleNameChange}
              />
              <button type="button" onClick={handleSavePage}>
                <FaSave />
              </button>
            </form>
            <form>
              <input
                type="text"
                name="formName"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={handleSubmit} >
                {isSending ? <FaHourglassEnd /> : <LuSend />}
              </button>
            </form>
            <div className="dropdown-footer-container">
              <p className="dropdown-footer">
                Vous pouvez fermer cette fiche et revenir dessus plus tard,
                l’enregistrement est automatique
              </p>
            </div>
          </div>
        </div>
      </div>

      <script
        src="https://kit.fontawesome.com/7a4c34e63c.js"
        crossOrigin="anonymous"
      ></script>
    </div>
  );
}

export default DropdownPage;
