import React from "react";
import "./PopUp.css"; // Import the CSS file

const PopUp = () => {
  return (
    <div className="popUp-container">
      <div className="popUp-form-deletePage">
        <p className="popUp-question">Voulez-vous supprimer cette fiche ?</p>
        <div className="popUp-bloc-btn">
          <button className="popUp-btn">Oui</button>
          <button className="popUp-btn">Annuler</button>
        </div>
      </div>
      <div className="popUp-form-cloturer">
        <p className="popUp-question">
          Etes-vous sûr(e) de clôturer vos fiches ?
        </p>
        <p className="popUp-txt-italic">
          Vous ne pourrez plus les modifier et recevrez cette version par mail
        </p>

        <div className="popUp-bloc-btn">
          <button className="popUp-btn">Oui, clôturer</button>
          <button className="popUp-btn">Annuler</button>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
