import React, { useState } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const SendEmail = () => {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [isSending, setIsSending] = useState(false);

    const generatePDF = async (content) => {
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        
        pdf.text(content, 10, 10, { maxWidth: pageWidth - 20 });

        return pdf.output('blob');
    };

    const handleSendEmail = async () => {
        if (isSending) return; // Prevent multiple submissions
        setIsSending(true);

        const savedPages = JSON.parse(localStorage.getItem('pages')) || [];
        const content = savedPages.map(page => `Page: ${page.name}\n${JSON.stringify(page.data, null, 2)}`).join('\n\n');
        const pdfBlob = await generatePDF(content);
        
        const formData = new FormData();
        formData.append('email', email);
        formData.append('subject', subject);
        formData.append('text', text);
        formData.append('attachment', new File([pdfBlob], 'form.pdf'));

        try {
            const response = await axios.post('http://localhost:3000/send-email', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);
            alert('Email sent successfully!');
        } catch (error) {
            console.error('There was an error sending the email!', error);
            alert('Failed to send email.');
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div>
            <div>
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Subject:</label>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Text:</label>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                ></textarea>
            </div>
            <button onClick={handleSendEmail} disabled={isSending}>
                {isSending ? 'Sending...' : 'Send Email'}
            </button>
        </div>
    );
};

export default SendEmail;
